"use client";

import * as Sentry from "@sentry/nextjs";
import { t } from "locales";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { Icon } from "components/Icon";
import { useRouter } from "next/navigation";
import { config } from "config";
import usePath from "hooks/usePath";
export default function NotFoundComponent() {
  const router = useRouter();
  const [q, setQ] = useState("");
  const path = usePath();
  const suggestion = querySuggestion(path);
  useEffect(() => {
    if (suggestion) {
      setQ(suggestion);
    }
  }, [suggestion]);
  useEffect(() => {
    Sentry.captureException("Page not found", {
      level: "warning"
    });
  }, []);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQ(e.target.value);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    router.push(`${config.rewrites["/search"]}?q=${q.trim()}`);
  };
  return <StyledGridContainer container alignItems="center" direction="column" justifyContent="center" spacing={4} data-sentry-element="StyledGridContainer" data-sentry-component="NotFoundComponent" data-sentry-source-file="NotFoundComponent.tsx">
            <Grid item data-sentry-element="Grid" data-sentry-source-file="NotFoundComponent.tsx">
                <Typography variant="h1" data-sentry-element="Typography" data-sentry-source-file="NotFoundComponent.tsx">{t["404"]["title"]}</Typography>
            </Grid>
            <Grid item data-sentry-element="Grid" data-sentry-source-file="NotFoundComponent.tsx">
                <Typography variant="h2" data-sentry-element="Typography" data-sentry-source-file="NotFoundComponent.tsx">{t["404"]["subtitle"]}</Typography>
            </Grid>
            <Grid item data-sentry-element="Grid" data-sentry-source-file="NotFoundComponent.tsx">
                <Typography variant="body2" component="span" dangerouslySetInnerHTML={{
        __html: t["404"]["description"]
      }} data-sentry-element="Typography" data-sentry-source-file="NotFoundComponent.tsx" />
            </Grid>
            <Grid item container component="form" onSubmit={handleSubmit} data-sentry-element="Grid" data-sentry-source-file="NotFoundComponent.tsx">
                <StyledTextField placeholder={t.search.search} inputProps={{
        "aria-label": t.search.search
      }} onChange={handleChange} value={q} data-sentry-element="StyledTextField" data-sentry-source-file="NotFoundComponent.tsx" />
                <StyledIconButton type="submit" aria-label="Search" data-sentry-element="StyledIconButton" data-sentry-source-file="NotFoundComponent.tsx">
                    <Icon name="search" data-sentry-element="Icon" data-sentry-source-file="NotFoundComponent.tsx" />
                </StyledIconButton>
            </Grid>
            <Grid item data-sentry-element="Grid" data-sentry-source-file="NotFoundComponent.tsx">
                <Link href="/" underline="always" variant="h4" data-sentry-element="Link" data-sentry-source-file="NotFoundComponent.tsx">
                    {t["go-to-homepage"]}
                </Link>
            </Grid>
        </StyledGridContainer>;
}
function querySuggestion(queryString: string | null): string {
  if (!queryString) {
    return "";
  }
  const qs = queryString.indexOf("/") > -1 ? queryString.split("/").slice(-1)[0] : queryString;
  return qs.replace(/-\d+.html/, "").replace(/\-/g, " ");
}
const StyledGridContainer = styled(Grid)(() => ({
  margin: "0 auto",
  maxWidth: "80vw",
  minHeight: "60vh"
}));
const StyledTextField = styled(TextField)(({
  theme
}) => ({
  flex: 1,
  marginLeft: theme.spacing(1)
}));
const StyledIconButton = styled(IconButton)(({
  theme
}) => ({
  padding: theme.spacing(2),
  marginLeft: theme.spacing(1)
}));